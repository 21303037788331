import './style.scss';
import * as SW from './serviceWorker';
import initClock from './clock';
import {
  FIRST_DAY,
  CHECK_DAYS,
  TITLE,
  DOCUMENT_TITLE,
  checkers,
} from './config';
import genChecker from './checkEvent';

SW.register();
// init
const firstDay = new Date(FIRST_DAY);
initClock(firstDay);
document.title = DOCUMENT_TITLE;
document.querySelector('.title').textContent = TITLE;

// notification element references for following operations
const notificationWrapper = document.querySelector('.notification-wrapper');
const notificationBell = document.querySelector('.notification-img');
const notificationContainer = document.querySelector('.notification');

// toggle notification modal
notificationWrapper.onclick = (e) => {
  if (e.target === notificationWrapper)
    notificationWrapper.style.display = 'none';
};

// if there is any event, clicking will open notification
notificationBell.onclick = () => {
  if (notificationBell.classList.contains('has')) {
    notificationWrapper.style.display = 'flex';
  }
};

// try to update events every second
const checkEvents = genChecker(FIRST_DAY);
let date = 0;
setInterval(() => {
  const newDate = new Date().getDate();
  if (date === newDate) return;
  date = newDate;
  const events = checkEvents(checkers).filter((c) => c.remain <= CHECK_DAYS);
  genNotifications(events);
}, 1000);
function genNotifications(events) {
  notificationContainer.innerHTML = '';
  if (!events.length) {
    notificationBell.classList.remove('has');
    notificationWrapper.style.display = 'none';
    return;
  }
  const frag = document.createDocumentFragment();
  events.forEach(({ name, remain, date }) => {
    const div = document.createElement('div');
    div.onclick = () => alert(date);
    switch (remain) {
      case 0:
        div.innerHTML = `今天就是 <span>${name}</span>！`;
        break;
      case 1:
        div.innerHTML = `明天就是 <span>${name}</span>！`;
        break;
      case 2:
        div.innerHTML = `後天就是 <span>${name}</span>！`;
        break;
      default:
        div.innerHTML = `距離 <span>${name}</span> 還有 ${remain} 天`;
    }
    frag.appendChild(div);
  });
  notificationBell.classList.add('has');
  notificationContainer.appendChild(frag);
  notificationWrapper.style.display = 'flex';
}
