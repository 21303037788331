// need JQuery
import './flipclock.min.js';
import './flipclock.css';
import './clock.css';

export default function makeClock(firstDay) {
  const now = new Date();
  const diff = (now - firstDay) / 1000;
  $('#clock').FlipClock(diff, {
    clockFace: 'DailyCounter',
  });
  const flips = $('.flip');
  const daysFlipsCount = flips.length - 6;
  const daysLabel = $('.days span');
  const daysLabelWidth = daysLabel.width();
  daysLabel.css(
    'left',
    (daysFlipsCount * flips.outerWidth(true)) / 2 - daysLabelWidth / 2
  );
}
