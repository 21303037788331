const lunarInfo = [
  0x04bd8, //1900-1909
  0x04ae0,
  0x0a570,
  0x054d5,
  0x0d260,
  0x0d950,
  0x16554,
  0x056a0,
  0x09ad0,
  0x055d2,
  0x04ae0, //1910-1919
  0x0a5b6,
  0x0a4d0,
  0x0d250,
  0x1d255,
  0x0b540,
  0x0d6a0,
  0x0ada2,
  0x095b0,
  0x14977,
  0x04970, //1920-1929
  0x0a4b0,
  0x0b4b5,
  0x06a50,
  0x06d40,
  0x1ab54,
  0x02b60,
  0x09570,
  0x052f2,
  0x04970,
  0x06566, //1930-1939
  0x0d4a0,
  0x0ea50,
  0x06e95,
  0x05ad0,
  0x02b60,
  0x186e3,
  0x092e0,
  0x1c8d7,
  0x0c950,
  0x0d4a0, //1940-1949
  0x1d8a6,
  0x0b550,
  0x056a0,
  0x1a5b4,
  0x025d0,
  0x092d0,
  0x0d2b2,
  0x0a950,
  0x0b557,
  0x06ca0, //1950-1959
  0x0b550,
  0x15355,
  0x04da0,
  0x0a5b0,
  0x14573,
  0x052b0,
  0x0a9a8,
  0x0e950,
  0x06aa0,
  0x0aea6, //1960-1969
  0x0ab50,
  0x04b60,
  0x0aae4,
  0x0a570,
  0x05260,
  0x0f263,
  0x0d950,
  0x05b57,
  0x056a0,
  0x096d0, //1970-1979
  0x04dd5,
  0x04ad0,
  0x0a4d0,
  0x0d4d4,
  0x0d250,
  0x0d558,
  0x0b540,
  0x0b6a0,
  0x195a6,
  0x095b0, //1980-1989
  0x049b0,
  0x0a974,
  0x0a4b0,
  0x0b27a,
  0x06a50,
  0x06d40,
  0x0af46,
  0x0ab60,
  0x09570,
  0x04af5, //1990-1999
  0x04970,
  0x064b0,
  0x074a3,
  0x0ea50,
  0x06b58,
  0x05ac0,
  0x0ab60,
  0x096d5,
  0x092e0,
  0x0c960, //2000-2009
  0x0d954,
  0x0d4a0,
  0x0da50,
  0x07552,
  0x056a0,
  0x0abb7,
  0x025d0,
  0x092d0,
  0x0cab5,
  0x0a950, //2010-2019
  0x0b4a0,
  0x0baa4,
  0x0ad50,
  0x055d9,
  0x04ba0,
  0x0a5b0,
  0x15176,
  0x052b0,
  0x0a930,
  0x07954, //2020-2029
  0x06aa0,
  0x0ad50,
  0x05b52,
  0x04b60,
  0x0a6e6,
  0x0a4e0,
  0x0d260,
  0x0ea65,
  0x0d530,
  0x05aa0, //2030-2039
  0x076a3,
  0x096d0,
  0x04afb,
  0x04ad0,
  0x0a4d0,
  0x1d0b6,
  0x0d250,
  0x0d520,
  0x0dd45,
  0x0b5a0, //2040-2049
  0x056d0,
  0x055b2,
  0x049b0,
  0x0a577,
  0x0a4b0,
  0x0aa50,
  0x1b255,
  0x06d20,
  0x0ada0,
  0x14b63, //2050-2059
  0x09370,
  0x049f8,
  0x04970,
  0x064b0,
  0x168a6,
  0x0ea50,
  0x06b20,
  0x1a6c4,
  0x0aae0,
  0x0a2e0, //2060-2069
  0x0d2e3,
  0x0c960,
  0x0d557,
  0x0d4a0,
  0x0da50,
  0x05d55,
  0x056a0,
  0x0a6d0,
  0x055d4,
  0x052d0, //2070-2079
  0x0a9b8,
  0x0a950,
  0x0b4a0,
  0x0b6a6,
  0x0ad50,
  0x055a0,
  0x0aba4,
  0x0a5b0,
  0x052b0,
  0x0b273, //2080-2089
  0x06930,
  0x07337,
  0x06aa0,
  0x0ad50,
  0x14b55,
  0x04b60,
  0x0a570,
  0x054e4,
  0x0d160,
  0x0e968, //2090-2099
  0x0d520,
  0x0daa0,
  0x16aa6,
  0x056d0,
  0x04ae0,
  0x0a9d4,
  0x0a2d0,
  0x0d150,
  0x0f252,
  0x0d520, //2100
];

/**
 * get days of the year
 * @param {number} y lunar year
 * @return {number} days of the year
 */
const getYearDays = (y) => {
  let sum = 0;
  for (let i = 0x8000; i > 0x8; i >>= 1) {
    sum += lunarInfo[y - 1900] & i ? 30 : 29;
  }
  return sum + getLeapDays(y);
};

/**
 * get leap month of the year
 * @param {number} y lunar year
 * @return {number} 1-12. 0 for non-leap year
 */
const getLeapMonth = (y) => {
  return lunarInfo[y - 1900] & 0xf;
};

/**
 * get leap month days of the year.
 * @param {number} y lunar year
 * @return {number} 29-30. 0 for non-leap year
 */
const getLeapDays = (y) => {
  if (getLeapMonth(y)) {
    return lunarInfo[y - 1900] & 0x10000 ? 30 : 29;
  }
  return 0;
};

/**
 * get non-leap month days of the year
 * @param {number} y lunar year
 * @param {number} m lunar month
 * @return {number} 29-30. 0 for invalid input
 */
const getMonthDays = (y, m) => {
  if (m > 12 || m < 1) {
    return 0;
  }
  return lunarInfo[y - 1900] & (0x10000 >> m) ? 30 : 29;
};

/**
 * convert lunar date to solar date. (1900/01/01-2100/12/29 -> 1900/01/31-2101/01/28)
 * @param y lunar year
 * @param m lunar month
 * @param d lunar day
 * @param isLeapMonth if the month is leap
 * @return {[number,number,number]} [y, m, d]
 */
export const lunar2Solar = (y, m, d, isLeapMonth = false) => {
  const leapMonth = getLeapMonth(y);
  if (isLeapMonth && leapMonth != m) {
    throw new Error(`month ${m} is not leap`);
  }
  const daysOfMonth = isLeapMonth ? getLeapDays(y) : getMonthDays(y, m);
  if (y < 1900 || y > 2100 || d > daysOfMonth) {
    throw new Error(`year ${y} is out of range`);
  }
  // calculate days offset from 1900
  let offset = 0;
  for (let i = 1900; i < y; i++) {
    offset += getYearDays(i);
  }
  for (let i = 1; i < m; i++) {
    offset += getMonthDays(y, i);
  }
  // add leap month days
  if (leapMonth < m) {
    offset += getLeapDays(y);
  }
  // add previous month days if this month is leap
  if (isLeapMonth) {
    offset += getMonthDays(y, m);
  }
  // lunar 1900/01/01 is equal to solar 1900/01/31
  const date = new Date(Date.UTC(1900, 0, 30));
  date.setDate(date.getUTCDate() + offset + d);
  return [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()];
};

export function checkOnLunar(name, date, month) {
  function checker(utils) {
    const event = new Date();
    event.setHours(0, 0, 0, 0);
    const thisYear = event.getFullYear();
    const [solarYear, solarMonth, solarDate] = lunar2Solar(
      thisYear,
      month,
      date
    );
    event.setFullYear(solarYear);
    event.setMonth(solarMonth - 1);
    event.setDate(solarDate);
    let diff = utils.diffDaysFromToday(event);
    if (diff < 0) {
      const [solarYear, solarMonth, solarDate] = lunar2Solar(
        thisYear + 1,
        month,
        date
      );
      event.setFullYear(solarYear);
      event.setMonth(solarMonth - 1);
      event.setDate(solarDate);
    }
    diff = utils.diffDaysFromToday(event);
    utils.add({
      name,
      remain: diff,
      date: event,
    });
  }
  Object.defineProperty(checker, 'name', {
    value: `checkOnLunar${month}/${date}`,
  });
  return checker;
}
