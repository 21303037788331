import day from 'dayjs';

export default function genChecker(firstDay) {
  return (checkers) =>
    checkEvent(firstDay, checkers).sort((a, b) => a.remain - b.remain);
}

/**
 *
 * utils.diffDaysFromFirst(date) - return number of days different from date to first day.
 * utils.diffDaysFromFirst(date) - return number of days different from date to now.
 * utils.add({ name: eventName, remain: days, date: Date }) - to add a event
 */
function checkEvent(firstDay, checkers) {
  const first = day(firstDay).startOf('day');
  const today = day().startOf('day');
  const events = [];
  const utils = {
    diffDaysFromFirst(date) {
      return day(date).diff(first, 'day');
    },
    diffDaysFromToday(date) {
      return day(date).diff(today, 'day');
    },
    add(event) {
      if (typeof event !== 'object') throw new Error('bad event type', event);
      if (
        typeof event.name === 'string' &&
        typeof event.remain === 'number' &&
        event.date instanceof Date
      )
        events.push(event);
      else throw new Error('bad event format', event);
    },
    getFirst() {
      return first.clone();
    },
  };
  checkers.forEach((checker) => {
    try {
      checker(utils);
    } catch (e) {
      console.log(e);
    }
  });
  return events;
}

// event checkers

/**
 * Generate a checker to check nearest date in future.
 * @param {string} name - name of the event
 * @param {number} date - date of the event
 * @param {number} month - month of the event (1 for January)
 */
export function checkOn(name, date, month) {
  function checker(utils) {
    let event = day()
      .month(month - 1)
      .date(date)
      .startOf('day');
    let diff = utils.diffDaysFromToday(event);
    if (diff < 0) {
      event = event.add(1, 'year');
      diff = utils.diffDaysFromToday(event);
    }
    utils.add({
      name,
      remain: diff,
      date: event.toDate(),
    });
  }
  Object.defineProperty(checker, 'name', { value: `checkOn${month}/${date}` });
  return checker;
}

/**
 * Generate a checker to check on every n days
 * @param {number} days - every offset between events
 * @param {string|function} naming - event name generator with a days argument
 * @param {number} maxDays - max days after first day
 */
export function checkEvery(days, naming, maxDays = Infinity) {
  function checker(utils) {
    const diff = utils.diffDaysFromFirst() + 1;
    // find diff days of nearest multiple of [days] in future
    // +1 because first day is also a day
    let nearestDays = Math.ceil(diff / days) * days;
    // today is first day
    if (diff === 1) nearestDays += days;
    if (nearestDays <= maxDays) {
      utils.add({
        name: naming
          ? typeof naming === 'function'
            ? naming(nearestDays)
            : naming
          : `${nearestDays} 天紀念`,
        remain: nearestDays - diff,
        date: day()
          .add(nearestDays - diff, 'day')
          .startOf('day')
          .toDate(),
      });
    }
  }
  Object.defineProperty(checker, 'name', { value: `checkEvery${days}` });
  return checker;
}

/**
 * Generate a checker to check nearest anniversary in future.
 * @param {string|function} naming - event name generator with a year argument
 */
export function checkAnniversary(naming) {
  function checker(utils) {
    const firstDay = utils.getFirst();
    let anniversary = firstDay.year(day().year());
    let today = day().startOf('day');
    if (anniversary.isBefore(today)) {
      anniversary = anniversary.add(1, 'year');
    }
    const diffDays = utils.diffDaysFromToday(anniversary);
    const diffYears = anniversary.diff(firstDay, 'year');
    utils.add({
      name: naming
        ? typeof naming === 'function'
          ? naming(diffYears)
          : naming
        : `${diffYears} 周年紀念`,
      remain: diffDays,
      date: anniversary.toDate(),
    });
  }
  Object.defineProperty(checker, 'name', { value: `checkAnniversary` });
  return checker;
}

/**
 * Generate a checker to check on every n seconds
 * @param {number} seconds - every offset between events
 * @param {string|function} naming - event name generator with a seconds argument
 * @param {number} maxSeconds - max seconds after first day
 */
export function checkEverySeconds(seconds, naming, maxSeconds = Infinity) {
  function checker(utils) {
    const firstDay = utils.getFirst();
    const diff = day().diff(firstDay, 'second');
    // find diff seconds of nearest multiple of [seconds] in future
    let nearestSeconds = Math.ceil(diff / seconds) * seconds;
    if (nearestSeconds <= maxSeconds) {
      const event = firstDay.add(nearestSeconds, 'second');
      utils.add({
        name: naming
          ? typeof naming === 'function'
            ? naming(nearestSeconds)
            : naming
          : `${nearestSeconds} 秒紀念`,
        remain: utils.diffDaysFromToday(event),
        date: event.toDate(),
      });
    }
  }
  Object.defineProperty(checker, 'name', {
    value: `checkEvery${seconds}Second`,
  });
  return checker;
}
